$( document ).ready(function() {
    _risestories();
});
const _risestories = () => {
    if ( $('.risestories').length > 0 ) {
        $('.risestories').each(function () {
            var _risestories = $(this),
                _risestories_carousel = $('.js-risestories-carousel', _risestories);
            
            _risestories_carousel.not('.slick-initialized').slick({
                accessibility: false,
                lazyLoad: "ondemand",
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                autoplay: true,
                autoplaySpeed: 8000,
                responsive: [
                    {
                        breakpoint: 9999,
                        settings: "unslick"
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
            _risestories.prev('section').addClass('has-sibbling--risestories');

            if ( $('.hotbox', _risestories).length == 0 ) {
                _risestories.addClass('no-stories');
            }

        });
    }
}